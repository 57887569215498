<script>
export default {

}
</script>

<template>
  <div>222222222222222222222222222222222</div>
</template>

<style scoped>

</style>