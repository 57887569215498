import { render, staticRenderFns } from "./ExtensionView.vue?vue&type=template&id=a60488f0&scoped=true"
import script from "./ExtensionView.vue?vue&type=script&lang=js"
export * from "./ExtensionView.vue?vue&type=script&lang=js"
import style0 from "./ExtensionView.vue?vue&type=style&index=0&id=a60488f0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a60488f0",
  null
  
)

export default component.exports