<script>
import ImgService from "@/service/ImgService";
import FileService from "@/service/FileService";
export default {
  data() {
    return {
      fileList: [],
      compressFileList : [],
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      compressVal: 50,
      compressValue : 0,
    };
  },
  methods: {
    async startDownload() {
      this.compressFileList.forEach((item) => {
        FileService.download(item.file,item.save_name);
      });
    },
    async startDownloadAsZip(){
      const loading = this.$loading({
        lock: true,
        text: '打包下载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      try {
        await FileService.downloadAsZip(this.compressFileList, (new Date()).getTime() + '.zip', 'file', 'save_name');
      } catch (e) {
        console.log('startDownloadAsZip err' + e.message);
      }
      loading.close();
    },
    async startCompress() {
      this.compressFileList = [];
      let k = 0;
      const loading = this.$loading({
        lock: true,
        text: '压缩中图片中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.compressValue = this.compressVal;
      for (const file of this.fileList) {
        try {
          const compressedFile = await ImgService.compressImage(file.raw, (100 - this.compressValue)/100);
          const imgBlob = ImgService.fileToBlob(compressedFile);
          this.compressFileList.push({
            before_size: ImgService.formatBytes(file.size),
            file: imgBlob,
            save_name : k+'.'+FileService.getExt(file.name),
            file_url : URL.createObjectURL(imgBlob),
            after_size: ImgService.formatBytes(compressedFile.size),
            k : k++,
          });
        } catch (error) {
          console.error('Compression Error:', error.message);
        }
      }
      loading.close();
    },
    handleRemove(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    handleChange(file, files) {
      console.log(file)
      this.fileList = files
    },
  }
}
</script>

<template>
  <div>
    <el-row :gutter="24">
      <el-col :span="12" :offset="6">
        <h1>选图</h1>
        <el-upload
            :auto-upload="false"
            :multiple="true"
            action="#"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-change="handleChange"
        >
          <i slot="default" class="el-icon-plus"></i>

        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="12" :offset="6">
        <div style="margin: 0 auto;" class="block">
          <span class="demonstration">压缩率</span>
          <el-slider
              v-model="compressVal"
              show-input>
          </el-slider>
          <el-button type="primary" plain @click="startCompress">开始压缩</el-button>
          <el-button type="success" plain @click="startDownload" v-show="compressFileList.length > 0">下载</el-button>
          <el-button type="warning" plain @click="startDownloadAsZip" v-show="compressFileList.length > 0">打包下载</el-button>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="24" v-show="compressFileList.length > 0">
      <el-col :span="12" :offset="6">
        <h1>压缩{{compressValue}}%结果</h1>
        <el-row :gutter="12" class="row-bg">
          <el-col :span="4"  v-for="(item) in compressFileList" :key="item.k">
            <el-image
                style="width: 100px; height: 100px"
                :src="item.file_url"
                :preview-src-list="compressFileList.map(it=>it.file_url)"
                fit="scale-down"></el-image>
            <p>压缩前:{{item.before_size}}</p>
            <p>压缩后:{{item.after_size}}</p>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped>
  .el-col{
    border: 1px solid red;
  }
</style>