<template>
  <div id="app">
    <el-menu  class="el-menu-demo" mode="horizontal" >
      <el-menu-item v-for="route in $router.options.routes" :key="route.path">
        <router-link :to="route.path">{{ route.name }}</router-link>
      </el-menu-item>
    </el-menu>

    <RouterView />
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {

  },
  data() {
    return {
      activeIndex: '1',
      activeIndex2: '1'
    };
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
