<script>
import imgRemoveBackground, { preload } from '@imgly/background-removal';


export default {
  name: 'App',
  data() {
    return {
      progress: {},
      imgs : [],
      preloaded : false,
    }
  },
  mounted() {
    let config = {
      debug: true,// enable or disable useful console.log outputs
      proxyToWorker: true, // Whether to proxy the calculations to a web worker. (Default true)
      model: 'medium', // The model to use. (Default "medium")
      output: {
        format: 'image/png', // The output format. (Default "image/png")
        quality: 1, // The quality. (Default: 0.8)
        type: 'foreground', // The output type. (Default "foreground")
      },
      progress: (key, current, total) => {
        if (this.progress[key] === undefined) {
          this.progress[key] = {
            key: key,
            current: 0,
            total: 0,
          };
        }
        this.progress[key] = {
          key: key,
          current: this.formatFileSize(current),
          total: this.formatFileSize(total),
          progress : (current * 100/total)+'%',
        };
        console.log(`Downloading ${key}: ${current} of ${total}`);
      }
    };
    //预加载
    preload(config).then(() => {
      this.preloaded = true;
      console.log("Background removal resources preloaded");
    });
  },
  methods: {
    formatFileSize(bytes) {
      if (bytes === 0) return '0 B';
      const thresholds = {
        'TB': Math.pow(1024, 4),
        'GB': Math.pow(1024, 3),
        'MB': Math.pow(1024, 2),
        'KB': 1024,
        'B' : 1,
      };

      function getSizeName(size) {
        for (let unit in thresholds) {
          if (size >= thresholds[unit]) {
            return unit;
          }
        }
      }

      const unit = getSizeName(bytes);
      const size = Math.round((bytes / thresholds[unit]) * 100) / 100; // 保留两位小数
      return `${size} ${unit}`;
    },
    onFileChange(e) {
      if (e.target.files.length <= 0) {
        return;
      }
      this.imgs = [];
      console.log(e.target.files)
      for (const file of e.target.files) {
        let imgInfo = {
          img : URL.createObjectURL(file),
          imgFile : file,
          snapImg : null,
          snapImgSize : 0,
          snapImgFile : null,
          imgError : false,
          startTime : (new Date()).getTime(),
          endTime : (new Date()).getTime(),
          timer : null,
          snapping : false,
          msg : '',
        }
        if (file.type.startsWith('image/gif')) {
          imgInfo.imgError = true;
          imgInfo.msg = 'gif不能用';
        }

        this.imgs.push(imgInfo);
      }
    },
    isSnaping() {
      for (const img of this.imgs) {
        if (img.snapping) {
          return true;
        }
      }
      return false;
    },

    snapMany() {
      this.imgs.forEach((img)=>{
        this.snap(img);
      });
    },

    async snap(item) {
      try {
        if (item.snapImg !== null) {
          //扣完了
          return;
        }
        if (item.imgError) {
          item.msg = '选择的图片文件有误';
          return;
        }
        if (!item.img) {
          item.msg = '没上传'
          return;
        }
        if (item.snapping) {
          return;
        }
        console.log(30);
        item.msg = '扣图中...'
        item.snapping = true;
        item.startTime = (new Date()).getTime();
        item.timer = setInterval(() => {
          item.endTime = (new Date()).getTime();
        });
        const blob = await imgRemoveBackground(item.img)
        clearInterval(item.timer);
        item.timer = null;
        item.endTime = (new Date()).getTime();
        console.log(32);
        // The result is a blob encoded as PNG. It can be converted to an URL to be used as HTMLImage.src
        item.snapImg = URL.createObjectURL(blob);
        item.snapImgFile = blob;
        item.snapping = false;
        item.msg = '完成';
      } catch (e) {
        item.msg = e.msg
        if (item.timer) {
          clearInterval(item.timer);
          item.timer = null;
        }
      }
    }
  }
}
</script>

<template>
  <div id="app" >
    <!--    <nav>-->
    <!--      <router-link to="/">Home</router-link>-->
    <!--      <router-link to="/about">About</router-link>-->
    <!--    </nav>-->
    <!--    <router-view/> &lt;!&ndash; 路由出口 &ndash;&gt;-->

    <div>
      <input :disabled="isSnaping()" type="file"
             accept="image/*"
             multiple="multiple" @change="onFileChange">
      <button type="button" :disabled="isSnaping()" @click="snapMany">抠图</button>
    </div>

    <div style="text-align: center;" v-if="imgs.length > 0">
      <table>
        <tr>
          <td>原图</td>
          <td>结果</td>
          <td>说明</td>
        </tr>
        <tr v-for="(item,key) in imgs" :key="key">
          <td>
            <img style="width:200px;border: 1px solid red;"
                 :src="item.img"
                 v-if="item.img"
                 :alt="item.imgFile.name"
                 @error="item.imgError=true;item.msg='图片文件有误;'"
            >
            <p class="img-info" v-if="item.imgFile">大小:{{formatFileSize(item.imgFile.size)}}</p>
          </td>
          <td style="background: #13ce66">
            <img style="width:200px;border: 1px solid red;"
                 :alt="'扣图后的'+item.imgFile.name" :src="item.snapImg"
                 v-if="item.snapImg">
            <p class="img-info" v-if="item.snapImgFile">大小:{{formatFileSize(item.snapImgFile.size)}}</p>
          </td>
          <td>
            <h2 style="color: red;">{{item.msg}}</h2>
            <span v-if="item.snapImg || item.snapping">用时:{{(item.endTime - item.startTime)/1000}}秒!</span>
          </td>
        </tr>
      </table>
    </div>

    <div>
      <h1>模型加载进度({{preloaded ? '已加载':'加载中'}})</h1>
      <table>
        <tr>
          <td>模型</td>
          <td>尺寸</td>
          <td>进度</td>
        </tr>
        <tr v-for="item in progress" :key="item.key">
          <td>{{ item.key }}</td>
          <td>{{ item.current }}/{{ item.total }}</td>
          <td>{{item.progress}}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
}
table {
  margin: 0 auto;
}
td {
  border: 1px solid #000;
  min-width: 200px;
}
.img-info{
  margin: 0;
}
</style>